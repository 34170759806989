/* eslint-disable */
import { mapState } from 'vuex'

import DocumentService from '@/services/Documents/DocumentsManagerService'
import { Events as DocumentEvents, Bus as DocumentEventBus } from '@/events/Documents/DocumentsEvents'

import NodeCreationMixin from '@/mixins/Documents/NodeCreationMixin'

export default {
	mixins: [NodeCreationMixin],
	data: function () {
		return {
			documentService: DocumentService,
			documentEventBus: DocumentEventBus,
			documentEvents: DocumentEvents,
			dropMixinZoneElementsAttribute: 'data-dropzone',
			dragAndDropMixinInternalMoveDataType: 'application/welyb-nodes-id-list',
			dropMixinListeners: [],
			tryToDropWhileFiltering: false
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest
		})
	},
	mounted: function () {
		this.___addDropMixinListeners()
	},
	updated: function () {
		this.___removeDropMixinListeners()
		this.___addDropMixinListeners()
	},
	destroyed: function () {
		this.___removeDropMixinListeners()
	},
	methods: {
		___canDropOnNode: function (node, isInternal) {
			return (
				!this.isGuest &&
				node != null &&
				node.is_folder &&
				!this.isFiltering &&
				(!node.is_readonly || node.is_trash) &&
				(!node.is_trash || isInternal) &&
				(!this.selectedNodes ||
					this.selectedNodes.length == 0 ||
					this.selectedNodes.every(selectedNode => selectedNode?.id == node.id) ||
					!this.selectedNodes.some(selectedNode => selectedNode?.id == node.id))
			)
		},
		___addDropMixinListeners: function () {
			document.querySelectorAll(`[${this.dropMixinZoneElementsAttribute}]:not([${this.dropMixinZoneElementsAttribute}=""])`).forEach(dropZoneElement => {
				this.___addDropMixinListener(dropZoneElement, 'dragenter', this.___onDragEnter)
				this.___addDropMixinListener(dropZoneElement, 'dragover', this.___onDragOver)
				this.___addDropMixinListener(dropZoneElement, 'dragleave', this.___onDragLeave)
				this.___addDropMixinListener(dropZoneElement, 'drop', this.___onDrop)
			})
		},
		___addDropMixinListener: function (domElement, listenedEvent, callBack) {
			domElement.addEventListener(listenedEvent, callBack, false)
			this.dropMixinListeners.push({ element: domElement, listenedEvent: listenedEvent, callBack: callBack })
		},
		___removeDropMixinListeners: function () {
			while (this.dropMixinListeners.length > 0) {
				const dropZone = this.dropMixinListeners.pop()
				dropZone.element?.removeEventListener(dropZone.listenedEvent, dropZone.callback, false)
			}
		},
		___onDragEnter: function (event) {
			event.preventDefault()
			const nodeElement = event.currentTarget
			this.___highlightNodeElement(nodeElement, event.dataTransfer.types.includes(this.dragAndDropMixinInternalMoveDataType))
		},
		___onDragOver: function (event) {
			event.preventDefault()
			event.stopPropagation()
			const nodeElement = event.currentTarget
			this.___highlightNodeElement(nodeElement, event.dataTransfer.types.includes(this.dragAndDropMixinInternalMoveDataType))
		},
		___onDragLeave: function (event) {
			const nodeElement = event.currentTarget
			this.___unhighlightNodeElement(nodeElement)
			this.tryToDropWhileFiltering = false
		},
		___onDrop: function (event) {
			event.preventDefault()
			event.stopPropagation()
			const nodeElement = event.currentTarget
			const node = this.___retrieveDroppedNodeFromEventNodeElement(nodeElement)
			this.___unhighlightNodeElement(nodeElement)
			if (event.dataTransfer.types.includes(this.dragAndDropMixinInternalMoveDataType)) {
				this.___onInternalDrop(event, node)
			} else {
				this.___onExternalDrop(event, node)
			}
		},
		___onInternalDropOnTrach: function (event, node) {
			if (node.is_trash) {
				this.documentEventBus.emit(this.documentEvents.DELETE_SELECTION)
			}
		},
		___onInternalDropAfterMoved: function (event, node) {
			let movedNodesIdList = JSON.parse(event.dataTransfer.getData(this.dragAndDropMixinInternalMoveDataType))
			movedNodesIdList = [...new Set(movedNodesIdList)]
			if (movedNodesIdList.find(nodeId => nodeId == node.id)) {
				return
			}
			const movedNodes = movedNodesIdList.map(nodeId => this.documentService.findNodeInStructure(nodeId, this.isFiltering))
			const nbOfManuallyReplicatedFolders = movedNodes.filter(movedNode => movedNode.is_manually_replicable).length
			let promise = Promise.resolve()
			if (nbOfManuallyReplicatedFolders > 0) {
				promise = this.$dialog
					.warning({
						text: this.$t('ecm.node_movement_dialog_text'),
						title: this.$t('ecm.node_movement_dialog_title'),
						actions: {
							false: this.$t('actions.no'),
							true: this.$t('actions.yes')
						}
					})
					.then(dialogResponse => {
						if (!dialogResponse) {
							throw new Error('The moving of nodes has been cancelled by the user')
						}
					})
			}

			promise.then(() => {
				movedNodes.forEach(movedNode => {
					let moveAction = null
					if (movedNode.is_folder) {
						movedNode.isFolder = true
						moveAction = this.documentService.updateFolderFolder
					} else if (movedNode.is_document) {
						moveAction = this.documentService.updateFolderDocument
					}
					if (moveAction && movedNode.parent != node) {
						this.__moveNode(node, movedNode, moveAction)
					}
				})
			})
		},
		___onInternalDrop: function (event, node) {
			if (node.is_trash) {
				this.___onInternalDropOnTrach(event, node)
			} else {
				this.___onInternalDropAfterMoved(event, node)
			}
		},
		___onExternalDrop: function (event, node) {
			if (event.dataTransfer.items.length > 0) {
				this.___uploadItems(
					node,
					[...event.dataTransfer.items].map(dataTransferItem => dataTransferItem.webkitGetAsEntry())
				)
			} else if (event.dataTransfer.files.length > 0) {
				this.___uploadItems(node, event.dataTransfer.files)
			}
		},
		___retrieveDroppedNodeFromEventNodeElement (nodeElement) {
			const nodeId = parseInt(nodeElement.getAttribute(`${this.dropMixinZoneElementsAttribute}`))
			return this.documentService.findNodeInStructure(nodeId)
		},
		___highlightNodeElement (nodeElement, internalDrop) {
			const node = this.___retrieveDroppedNodeFromEventNodeElement(nodeElement)
			if (this.___canDropOnNode(node, internalDrop)) {
				event.dataTransfer.dropEffect = internalDrop ? 'move' : 'copy'
				nodeElement.style.backgroundColor = 'rgba(0, 255, 0, 0.25)'
				if (!node?.opened && node?.folder_id) {
					const nodeIconElement = nodeElement.querySelector('i')
					nodeIconElement.classList.replace('fa-folder', 'fa-folder-open')
				}
			} else {
				event.dataTransfer.dropEffect = 'none'
				nodeElement.style.backgroundColor = 'rgba(255, 0, 0, 0.25)'
				if (this.isFiltering && !this.tryToDropWhileFiltering) {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('ecm.cannot_move_search_result'))
					this.tryToDropWhileFiltering = true
				}
			}
		},
		___unhighlightNodeElement (nodeElement) {
			const node = this.___retrieveDroppedNodeFromEventNodeElement(nodeElement)
			nodeElement.style.backgroundColor = null
			if (!node?.opened && node?.folder_id) {
				const nodeIconElement = nodeElement.querySelector('i')
				nodeIconElement.classList.replace('fa-folder-open', 'fa-folder')
			}
		}
	}
}
