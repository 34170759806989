import SelectionJs from '@simonwep/selection-js'

import DocumentService from '@/services/Documents/DocumentsManagerService'
import { Events as DocumentEvents, Bus as DocumentEventBus } from '@/events/Documents/DocumentsEvents'

export default {
	data: function () {
		return {
			documentService: DocumentService,
			documentBus: DocumentEventBus,
			documentEvents: DocumentEvents,
			selectionZoneQuerySelector: '#mouse_selection_box_container',
			selectionBoxClass: 'mouse-selection-box',
			ecmDomElementAttribute: 'data-node-id',
			mouseSelection: null,
			addedNodeIds: new Set(),
			removedNodeIds: new Set(),
			timeout: null,
			updateDelayTime: 10,
			hasStartedWithCtrlKeyPress: null
		}
	},
	mounted: function () {
		this.mouseSelection = SelectionJs.create({
			startareas: [this.selectionZoneQuerySelector],
			boundaries: [this.selectionZoneQuerySelector],
			selectionAreaContainer: this.selectionZoneQuerySelector,
			class: this.selectionBoxClass,
			disableTouch: false,
			selectables: [`[${this.ecmDomElementAttribute}]`]
		})
			.on('beforestart', this.onSelectionBeforeStart.bind(this))
			.on('start', this.onSelectionStart.bind(this))
			.on('move', this.onSelectionMove.bind(this))
			.on('stop', this.onSelectionStop.bind(this))
	},
	destroyed: function () {
		this.mouseSelection.destroy()
	},
	methods: {
		onSelectionBeforeStart: function ({ oe: event }) {
			if ((this.OS.isMacOS && !event.metaKey && !event.shiftKey) || ((this.OS.isLinux || this.OS.isWindows) && !event.ctrlKey && !event.shiftKey)) {
				this.selectedNodes = null
			}
			return event.button === 0 || event.button === 2
		},
		onSelectionStart: function () {
			this.documentBus.emit(this.documentEvents.SELECTION_START)
		},
		onSelectionMove: function ({ changed: { added, removed }, oe: event }) {
			if (this.hasStartedWithCtrlKeyPress == null) {
				this.hasStartedWithCtrlKeyPress = (this.OS.isMacOS && event.metaKey) || ((this.OS.isLinux || this.OS.isWindows) && event.ctrlKey)
			}
			if (Array.isArray(added)) {
				added.forEach(domNode => {
					const nodeId = parseInt(domNode?.getAttribute(`${this.ecmDomElementAttribute}`))
					const node = this.documentService.findNodeInStructure(nodeId, this.isFiltering)
					const nodeIndex = this.selectedNodes.findIndex(selectedNode => selectedNode.id == node.id)
					if (nodeIndex != -1 && this.hasStartedWithCtrlKeyPress) {
						this.selectedNodes.splice(nodeIndex, 1)
					} else if (nodeIndex == -1) {
						this.selectedNodes.push(node)
					}
				})
			}
			if (Array.isArray(removed)) {
				removed.forEach(domNode => {
					const nodeId = parseInt(domNode?.getAttribute(`${this.ecmDomElementAttribute}`))
					const node = this.documentService.findNodeInStructure(nodeId, this.isFiltering)
					const nodeIndex = this.selectedNodes.findIndex(selectedNode => selectedNode.id == node.id)
					if (nodeIndex != -1) {
						this.selectedNodes.splice(nodeIndex, 1)
						domNode.classList.remove(this.ecmDomElementsSelectedClass)
						domNode.classList.add(this.ecmDomElementsNotSelectedClass)
					}
				})
			}
		},
		onSelectionStop: function () {
			this.hasStartedWithCtrlKeyPress = null
			this.documentBus.emit(this.documentEvents.SELECTION_STOP)
		}
	}
}
