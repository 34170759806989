import { mapGetters, mapState } from 'vuex'

export default {
	data: function () {
		return {
			directions: {
				left: 'left',
				right: 'right',
				up: 'up',
				down: 'down'
			},
			previousIndex: -1,
			previousLineIndex: -1,
			previousNodeIndex: -1
		}
	},
	computed: {
		...mapState({
			pauseKeyboardListening: state => state.pauseKeyboardListening,
			preventDefaultKeyboardEvents: state => state.preventDefaultKeyboardEvents
		}),
		...mapGetters({
			previewHasPreventedKeyboardKey: 'documents/preview/hasPreventKeyboardKey'
		}),
		nodesMatrix: function () {
			return this.lines
				.filter(line => line.hasRealNodes)
				.map(line => {
					return {
						id: line.id,
						nodes: line.nodes.filter(lineNode => lineNode.isBackToParentFolder || lineNode.isNode)
					}
				})
		}
	},
	watch: {
		selectedNodes: {
			handler (newValue) {
				if (!newValue || !Array.isArray(newValue) || newValue.length === 0) {
					this.resetIndices();
					return;
				}
				this.updateIndices(newValue);
			}
		}
	},
	methods: {
		resetIndices () {
			this.previousIndex = -1;
			this.previousLineIndex = -1;
			this.previousNodeIndex = -1;
		},
		updateIndices (newValue) {
			const lastElement = newValue[newValue.length - 1];
			this.previousIndex = this.findIndex(this.sortedChildren, lastElement);
			this.previousLineIndex = this.findLineIndex(this.nodesMatrix, lastElement);
			this.previousNodeIndex = this.previousLineIndex === -1
				? -1
				: this.findNodeIndex(this.nodesMatrix[this.previousLineIndex].nodes, lastElement);
		},
		findIndex (array, element) {
			return array.findIndex(node => node?.id === element?.id);
		},
		findLineIndex (matrix, element) {
			return matrix.findIndex(line => line.nodes.some(lineNode => lineNode.node?.id === element?.id));
		},
		findNodeIndex (nodes, element) {
			return nodes.findIndex(lineNode => lineNode.node?.id === element.id);
		},
		getKeyboardEventsActionsMapping: function () {
			return [
				{
					keyboardEvent: this.keyboard.events.keyDown,
					actions: [
						{
							key: this.keyboard.escape,
							action: this.clearSelectedNodes
						},
						{
							preventDefault: () => this.preventDefaultKeyboardEvents,
							key: this.keyboard.a,
							modifiers: [this.keyboard.modifiers.ctrl],
							os: [this.OS.WINDOWS, this.OS.LINUX],
							action: this.selectAllNodes
						},
						{
							preventDefault: () => this.preventDefaultKeyboardEvents,
							key: this.keyboard.a,
							modifiers: [this.keyboard.modifiers.meta],
							os: [this.OS.MACOS],
							action: this.selectAllNodes
						},
						{
							key: this.keyboard.home,
							action: this.selectFirstNode
						},
						{
							key: this.keyboard.end,
							action: this.selectLastNode
						},
						{
							key: this.keyboard.arrow.left,
							preventDefault: false,
							action: () => {
								if (this.previewHasPreventedKeyboardKey(this.keyboard.arrow.left)) {
									return;
								}

								this.selectLeftNode()
							}
						},
						{
							key: this.keyboard.arrow.right,
							preventDefault: false,
							action: () => {
								if (this.previewHasPreventedKeyboardKey(this.keyboard.arrow.right)) {
									return;
								}

								this.selectRightNode()
							}
						},
						{
							key: this.keyboard.arrow.up,
							preventDefault: false,
							action: () => {
								if (this.previewHasPreventedKeyboardKey(this.keyboard.arrow.up)) {
									return;
								}

								this.selectUpperNode()
							}
						},
						{
							key: this.keyboard.arrow.down,
							preventDefault: false,
							action: () => {
								if (this.previewHasPreventedKeyboardKey(this.keyboard.arrow.down)) {
									return;
								}

								this.selectLowerNode()
							}
						},
						{
							key: this.keyboard.page.up,
							action: this.selectPageUpperNode
						},
						{
							key: this.keyboard.page.down,
							action: this.selectPageLowerNode
						},
						{
							key: this.keyboard.home,
							modifiers: [this.keyboard.modifiers.shift],
							action: this.alsoSelectFirstNode
						},
						{
							key: this.keyboard.end,
							modifiers: [this.keyboard.modifiers.shift],
							action: this.alsoSelectLastNode
						},
						{
							key: this.keyboard.arrow.left,
							modifiers: [this.keyboard.modifiers.shift],
							action: this.alsoSelectLeftNode
						},
						{
							key: this.keyboard.arrow.right,
							modifiers: [this.keyboard.modifiers.shift],
							action: this.alsoSelectRightNode
						},
						{
							key: this.keyboard.arrow.up,
							modifiers: [this.keyboard.modifiers.shift],
							action: this.alsoSelectUpperNodes
						},
						{
							key: this.keyboard.arrow.down,
							modifiers: [this.keyboard.modifiers.shift],
							action: this.alsoSelectLowerNodes
						},
						{
							key: this.keyboard.page.up,
							modifiers: [this.keyboard.modifiers.shift],
							action: this.alsoSelectPageUpperNodes
						},
						{
							key: this.keyboard.page.down,
							modifiers: [this.keyboard.modifiers.shift],
							action: this.alsoSelectPageLowerNodes
						}
					]
				}
			]
		},
		clearSelectedNodes: function () {
			if (!this.pauseKeyboardListening) {
				this.lastSelectedNodeIndex = null
				this.selectedNodes.splice(0, this.selectedNodes.length)
			}
		},
		selectAllNodes: function () {
			if (!this.pauseKeyboardListening) {
				this.selectedNodes.splice(0, this.selectedNodes.length, ...this.sortedChildren.filter(sortedChild => !sortedChild.is_trash))
			}
		},
		selectFirstNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.up, this.nodesMatrix.length, this.sortedChildren.length)
			}
		},
		selectLastNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.down, this.nodesMatrix.length, this.sortedChildren.length)
			}
		},
		selectLeftNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.left, 0, 1)
			}
		},
		selectRightNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.right, 0, 1)
			}
		},
		selectUpperNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.up, 1, 0)
			}
		},
		selectLowerNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.down, 1, 0)
			}
		},
		selectPageUpperNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.up, this.numberOfNodesPerLine, 0)
			}
		},
		selectPageLowerNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.down, this.numberOfNodesPerLine, 0)
			}
		},
		alsoSelectFirstNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.up, this.nodesMatrix.length, this.sortedChildren.length, true)
			}
		},
		alsoSelectLastNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.down, this.nodesMatrix.length, this.sortedChildren.length, true)
			}
		},
		alsoSelectLeftNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.left, 0, 1, true)
			}
		},
		alsoSelectRightNode: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.right, 0, 1, true)
			}
		},
		alsoSelectUpperNodes: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.up, 1, 0, true)
			}
		},
		alsoSelectLowerNodes: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.down, 1, 0, true)
			}
		},
		alsoSelectPageUpperNodes: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.up, this.numberOfNodesPerLine, 0, true)
			}
		},
		alsoSelectPageLowerNodes: function () {
			if (!this.pauseKeyboardListening) {
				this.keyboardSelectNewNode(this.directions.down, this.numberOfNodesPerLine, 0, true)
			}
		},
		// eslint-disable-next-line sonarjs/cognitive-complexity
		getNewCoords: function (direction, linePitch, rowPitch) {
			const doIncrement = [this.directions.right, this.directions.down].includes(direction)
			let line = this.previousLineIndex < 0 ? 0 : this.previousLineIndex
			let row = this.previousNodeIndex < 0 ? 0 : this.previousNodeIndex

			if (linePitch != 0) {
				line = doIncrement
					? Math.min(this.nodesMatrix.length - 1, this.previousLineIndex + linePitch)
					: Math.max(0, this.previousLineIndex - linePitch)
			}
			if (rowPitch) {
				row = doIncrement
					? Math.min(this.nodesMatrix[line].nodes.length - 1, this.previousNodeIndex + rowPitch)
					: Math.max(0, this.previousNodeIndex - rowPitch)
			} else if (line != this.previousLineIndex) {
				row = Math.min(this.nodesMatrix[line].nodes.length - 1, row)
			}

			if ((linePitch != 0 || rowPitch != 0) && line == this.previousLineIndex && row == this.previousNodeIndex) {
				switch (direction) {
					case this.directions.down:
						line = this.nodesMatrix.length - 1
						row = this.nodesMatrix[line].nodes.length - 1
						break
					case this.directions.left:
						line = Math.max(0, line - 1)
						row = this.nodesMatrix[line].nodes.length - 1
						break
					case this.directions.right:
						line = Math.min(line + 1, this.nodesMatrix.length - 1)
						row = line != this.previousLineIndex ? 0 : row
						break
					case this.directions.up:
						line = 0
						row = 0
						break
				}
			}

			if (line === 0 && row === 0 && this.nodesMatrix[0].nodes[0]?.isBackToParentFolder) {
				// If in a subfolder and the first node is "Go Back", skip it
				row = this.nodesMatrix[0].nodes.length > 1 ? 1 : 0;
				line = this.nodesMatrix.length > 1 ? 1 : 0;
			}

			return { line, row }
		},
		keyboardSelectNewNode: function (direction, linePitch, rowPitch, append = false) {
			const doIncrement = [this.directions.right, this.directions.down].includes(direction)
			const { line: newLineIndex, row: newNodeIndex } = this.getNewCoords(direction, linePitch, rowPitch)
			const newIndex = this.sortedChildren.findIndex(sortedChild => sortedChild.id == this.nodesMatrix[newLineIndex].nodes[newNodeIndex].node.id)
			const newNode = this.sortedChildren[newIndex]
			const concernedNodes = this.sortedChildren.slice(doIncrement ? this.previousIndex : newIndex, (doIncrement ? newIndex : this.previousIndex) + 1)
			const selectedNodesIds = this.selectedNodes.map(selectedNode => selectedNode.id)
			if (!append) {
				this.selectedNodes = this.sortedChildren[newIndex]
			} else if (concernedNodes.every(concernedNode => selectedNodesIds.includes(concernedNode.id))) {
				for (const concernedNode of concernedNodes) {
					const concernedNodeIndex = this.selectedNodes.findIndex(selectedNode => selectedNode.id == concernedNode.id)
					if (concernedNodeIndex != -1) {
						this.selectedNodes.splice(concernedNodeIndex, 1)
					}
				}
				this.selectedNodes.push(this.sortedChildren[newIndex])
			} else {
				this.selectedNodes.push(...concernedNodes.filter(concernedNode => !selectedNodesIds.includes(concernedNode.id)))
			}
			this.scrollToLastSelectedNode(newNode)
		},
		scrollToLastSelectedNode: function (lastSelectedNode) {
			if (lastSelectedNode) {
				const lastSelectedNodeDomElement = document.querySelector(`[data-node-id="${lastSelectedNode.id}"]`)
				const nodesContainerDomElement = document.getElementById('mouse_selection_box_container')
				if (lastSelectedNodeDomElement) {
					const offset = Math.floor(lastSelectedNodeDomElement.getBoundingClientRect().top) + this.nodeLineHeight
					if (offset > window.innerHeight) {
						nodesContainerDomElement.scrollTop += offset + this.nodeLineHeight - window.innerHeight
					} else if (offset < 0) {
						nodesContainerDomElement.scrollTop = offset
					}
				}
			}
		}
	}
}
