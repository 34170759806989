import { mapState } from 'vuex'

import DocumentService from '@/services/Documents/DocumentsManagerService'

export default {
	data: function () {
		return {
			documentService: DocumentService,
			dragMixinElementsAttribute: 'data-draggable',
			dragMixinDraggedElementsContainerId: 'dragged-elements-container',
			dragAndDropMixinInternalMoveDataType: 'application/welyb-nodes-id-list',
			dragMixinListeners: [],
			dragMixinDraggedElements: []
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest,
			darkMode: state => state.user.darkMode
		})
	},
	mounted: function () {
		this.___addDragListeners()
	},
	updated: function () {
		this.___removeDragListeners()
		this.___addDragListeners()
	},
	destroyed: function () {
		this.___removeDragListeners()
	},
	methods: {
		___canDragNode: function (node) {
			if (this.isGuest || !node) {
				return false;
			}

			const isRegularNode = !node.is_root && !node.is_trash && (!node.is_default || node.is_manually_replicable);

			return (this.isFiltering || (node.parent && !node.parent.is_readonly)) &&
				(!node.is_folder || isRegularNode) &&
				(this.selectedNodes?.length === 0 || this.selectedNodes?.some(selectedNode => selectedNode.id === node.id));
		},
		___addDragListeners: function () {
			document.querySelectorAll(`[${this.dragMixinElementsAttribute}]:not([${this.dragMixinElementsAttribute}=""])`).forEach(draggableElement => {
				const nodeId = parseInt(draggableElement.getAttribute(`${this.dragMixinElementsAttribute}`))
				const node = this.documentService.findNodeInStructure(parseInt(nodeId), this.isFiltering)
				if (this.___canDragNode(node)) {
					draggableElement.setAttribute('draggable', true)
					this.___addDragListener(draggableElement, 'dragstart', this.___onDragStart)
					this.___addDragListener(draggableElement, 'drag', this.___onDrag)
					this.___addDragListener(draggableElement, 'dragend', this.___onDragEnd)
				}
			})
		},
		___addDragListener: function (domElement, listenedEvent, callBack) {
			domElement.addEventListener(listenedEvent, callBack, false)
			this.dragMixinListeners.push({ element: domElement, listenedEvent: listenedEvent, callBack: callBack })
		},
		___removeDragListeners: function () {
			while (this.dragMixinListeners.length > 0) {
				const dropTarget = this.dragMixinListeners.pop()
				dropTarget.element?.removeEventListener(dropTarget.listenedEvent, dropTarget.callback, false)
			}
		},
		___haveConciseSelectedNodes: function (node) {
			if (!this.selectedNodes.some(selectedNode => selectedNode.id == node.id)) {
				this.selectedNodes.splice(0, this.selectedNodes.length, node)
			}
		},
		___onDragStart: function (event) {
			const nodeElement = event.target
			const node = this.___retrieveDraggedNodeFromEventNodeElement(nodeElement)
			if (!this.selectedNodes.some(selectedNode => selectedNode.id == node.id)) {
				this.selectedNodes.splice(0, this.selectedNodes.length, node)
			}
			const query = this.selectedNodes.map(draggedNode => `[${this.dragMixinElementsAttribute}="${draggedNode.id}"]`).join(', ')
			this.dragMixinDraggedElements.splice(0, this.dragMixinDraggedElements.length, ...document.querySelectorAll(query))
			const nodesList = this.dragMixinDraggedElements.map(draggedElement => this.___retrieveDraggedNodeFromEventNodeElement(draggedElement))
			const nodesIdList = nodesList.map(draggedNode => draggedNode.id)
			if (node.is_document) {
				event.dataTransfer.setData('DownloadURL', `${node.type}:${node.name}:${node.uri ? node.uri : node.latestVersion.uri}`)
				event.dataTransfer.setData('text/plain', `${nodesList.map(draggedNode => draggedNode.name).join('\n')}`)
				event.dataTransfer.setData('text/html', `${nodesList.map(draggedNode => draggedNode.name).join('<br>')}`)
				event.dataTransfer.setData(
					'text/uri-list',
					`${nodesList.map(draggedNode => new URL(draggedNode.uri ? draggedNode.uri : draggedNode.latestVersion.uri)).join('\n')}`
				)
			}
			event.dataTransfer.effectAllowed = 'copy,move'
			event.dataTransfer.setData(this.dragAndDropMixinInternalMoveDataType, JSON.stringify(nodesIdList))
			this.___haveConciseSelectedNodes(node)
			this.___addDomElementsGhost(nodeElement)
			this.___fadeSourceDomElement()
			this.___customizeCursor()
		},
		___onDrag: function () {
			this.___customizeCursor()
		},
		___onDragEnd: function (event) {
			event.preventDefault()
			event.stopPropagation()
			this.___restoreCursor()
			this.___unfadeSourceElement()
			this.___removeDomElementsGhost()
		},
		___retrieveDraggedNodeFromEventNodeElement: function (nodeElement) {
			const attributeValue = nodeElement.getAttribute(`${this.dragMixinElementsAttribute}`)
			const nodeId = parseInt(attributeValue)
			return this.documentService.findNodeInStructure(nodeId, this.isFiltering)
		},
		___customizeCursor: function () {
			document.body.style.cursor = 'grab'
		},
		___fadeSourceDomElement: function () {
			this.dragMixinDraggedElements.forEach(draggedNodeImage => {
				draggedNodeImage.style.opacity = 0.3
			})
		},
		___addDomElementsGhost: function (nodeElement) {
			const ghostImage = document.createElement('div')
			ghostImage.id = this.dragMixinDraggedElementsContainerId
			ghostImage.style.position = 'absolute'
			ghostImage.style.top = '-1000px'
			ghostImage.style.height = `${this.nodeLineHeight}px`
			ghostImage.style.width = `${this.nodeLineHeight}px`
			ghostImage.style.zIndex = '100'
			const ghostImageContainer = document.createElement('div')
			ghostImageContainer.classList.add('flex')
			ghostImageContainer.style.position = 'relative'
			const nodeElementCopy = nodeElement.cloneNode(true)
			nodeElementCopy.classList.remove('shrink')
			Array.from(nodeElementCopy.classList)
				.filter(className => /^(xs|sm|md|lg|xl)\d$/.test(className))
				.forEach(className => {
					nodeElementCopy.classList.remove(className)
				})
			ghostImageContainer.appendChild(nodeElementCopy)
			if (this.selectedNodes.length > 1) {
				const numberOfDraggedElements = document.createElement('div')
				numberOfDraggedElements.innerHTML = this.selectedNodes.length
				numberOfDraggedElements.style.position = 'absolute'
				numberOfDraggedElements.style.top = '50%'
				numberOfDraggedElements.style.left = '50%'
				numberOfDraggedElements.style.transform = 'translate(-50%, -50%)'
				numberOfDraggedElements.style.backgroundColor = '#0074CC'
				numberOfDraggedElements.style.border = `2px solid ${this.darkMode ? '#FFFFFF' : '#303030'}`
				numberOfDraggedElements.style.fontWeight = 'bold'
				numberOfDraggedElements.style.padding = '3px'
				ghostImageContainer.appendChild(numberOfDraggedElements)
			}
			ghostImage.appendChild(ghostImageContainer)
			this.$el.appendChild(ghostImage)
			event.dataTransfer.setDragImage(nodeElementCopy, this.nodeLineHeight / 2, this.nodeLineHeight)
			nodeElementCopy.style.opacity = 1
		},
		___restoreCursor: function () {
			document.body.style.removeProperty('cursor')
		},
		___unfadeSourceElement: function () {
			while (this.dragMixinDraggedElements.length > 0) {
				const draggedElement = this.dragMixinDraggedElements.pop()
				draggedElement.style.opacity = 1
			}
		},
		___removeDomElementsGhost: function () {
			const dragMixinDraggedElementsContainer = document.getElementById(this.dragMixinDraggedElementsContainerId)
			if (dragMixinDraggedElementsContainer && dragMixinDraggedElementsContainer.parentNode) {
				dragMixinDraggedElementsContainer.parentNode.removeChild(dragMixinDraggedElementsContainer)
			}
		}
	}
}
